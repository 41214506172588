import characterInfo from '../character-info'

export default function KeyboardController (keys) {
  const [keyLeft, keyRight, keyJump, keyShoot] = keys

  return {
    reset: () => {
      keyLeft.unbindPress()
      keyRight.unbindPress()
      keyJump.unbindPress()
      keyShoot.unbindPress()
    },
    setupForCharacterSelect: ([_, setCharIndex], startGame) => {
      keyLeft.press(() => {
        setCharIndex(i => i <= 0 ? characterInfo.length - 1 : i - 1)
      })
      keyRight.press(() => {
        setCharIndex(i => (i + 1) % characterInfo.length)
      })
      keyShoot.press(() => {
        startGame()
      })

      return () => {
        keyLeft.unbindPress()
        keyRight.unbindPress()
        keyShoot.unbindPress()
      }
    },
    setupForRestart: (onRestart) => {
      keyShoot.press(() => onRestart())
    },
    setup: (state) => {
      keyJump.press(() => {
        state.jump = true
      })
      keyShoot.press(() => {
        state.shoot = true
      })
    },
    update: (state, delta) => {
      state.isMoving = false

      if (keyLeft.isDown()) {
        state.isMoving = true
        // state.xSpeed = Math.max(-1, state.xSpeed - 0.1 * delta)
        state.xDir = -1

        const targetVX = -1 * state.speedMod * state.info.speed
        if (state.entity.vx > targetVX) {
          const vxDiff = Math.min(state.speedMod * -state.info.speed, targetVX - state.entity.vx)
          const vxAdd = vxDiff * 0.05 * delta
          state.entity.vx += vxAdd
        }
      }
      if (keyRight.isDown()) {
        state.isMoving = true
        // state.xSpeed = Math.min(1, state.xSpeed + 0.1 * delta)
        state.xDir = 1

        const targetVX = 1 * state.speedMod * state.info.speed
        if (state.entity.vx < targetVX) {
          const vxDiff = Math.min(state.speedMod * state.info.speed, targetVX - state.entity.vx)
          const vxAdd = vxDiff * 0.05 * delta
          state.entity.vx += vxAdd
        }
      }
    }
  }
}
