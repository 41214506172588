import * as PIXI from 'pixi.js'
// import Bump from 'pixi-plugin-bump/src/Bump'
import circleBox from 'intersects/circle-box'

let bulletIdCounter = 0

export default function BasicHitBullet (x, y, xDir, characterId) {
  const radius = 20
  const node = new PIXI.Container()
  node.x = x
  node.y = y
  node.zIndex = 10

  // const circle = new PIXI.Graphics()
  // circle.beginFill(0x00ff00)
  // circle.drawCircle(0, 0, radius)
  // circle.circular = true
  // // circle.alpha = 0
  // node.addChild(circle)

  const visual = new PIXI.Graphics()
  visual.beginFill(0xe04a4a)
  // visual.lineStyle(radius * 0.5, 0xe04a4a)
  visual.drawCircle(0, 0, radius * 0.6)
  node.addChild(visual)

  const id = ++bulletIdCounter
  let duration = 0
  let hasCheckedOnceForHit = false

  return {
    id,
    node,
    setup: function (parentNode, world) {
      if (!node.parent && !this.isRemoved) {
        parentNode.addChild(node)
      }
    },
    update: function (delta, gameState) {
      if (!node.parent || this.isRemoved) return

      // Only check hit on first frame
      if (!hasCheckedOnceForHit) {
        // const b = new Bump()

        for (const character of gameState.characters) {
          if (character.id === characterId) continue

          // b.hitTestCircleRectangle(circle, character.referenceRectangle, true)
          const intersected = circleBox(
            node.x,
            node.y,
            radius,
            character.node.x - character.referenceRectangle.width / 2,
            character.node.y - character.referenceRectangle.height / 2,
            character.referenceRectangle.width,
            character.referenceRectangle.height
          )

          // console.log(
          //   'hittest',
          //   intersected
          //   // circle.gx,
          //   // character.referenceRectangle.gx,
          //   // b.hitTestCircleRectangle(circle, character.referenceRectangle, true)
          //   // b.hitTestRectangle(circle, character.referenceRectangle, true)
          // )
          // if (b.hitTestCircleRectangle(circle, character.referenceRectangle, true)) {
          if (intersected) {
            const power = 20

            gameState.addToCameraShake(power / 2)
            character.hit(xDir, power)
            break
          }
        }

        hasCheckedOnceForHit = true
      }

      // Remove after duration
      duration = Math.min(1, duration + delta * 0.1)
      visual.scale.x = 1 + duration * 0.5
      visual.scale.y = 1 + duration * 0.5
      visual.alpha = 1 - duration

      if (duration >= 1) {
        node.parent.removeChild(node)
        this.isRemoved = true
      }
    },
    render: () => {}
  }
}
