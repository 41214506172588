import BasicHitBullet from './entities/BasicHitBullet'
import FireballBullet from './entities/FireballBullet'

export default [
  {
    name: 'Kaoser',
    image: require('./images/kaoser.png').default,
    imageScale: 0.12,
    imageOffsetY: 16,
    speed: 300,
    jumpForce: 700,
    maxJumps: 2,
    bulletType: FireballBullet,
    width: 50,
    height: 50,
    reloadTime: 25
  },
  {
    name: 'Captain Gummybear',
    image: require('./images/captaingummybear.png').default,
    imageScale: 0.12,
    imageOffsetY: 16,
    speed: 350,
    jumpForce: 550,
    maxJumps: 5,
    bulletType: BasicHitBullet,
    width: 40,
    height: 50,
    reloadTime: 5
  },
  {
    name: 'Wacman',
    image: require('./images/wacman.png').default,
    imageScale: 0.12,
    imageOffsetY: 24,
    speed: 150,
    jumpForce: 1000,
    maxJumps: 2,
    bulletType: FireballBullet,
    width: 55,
    height: 70,
    reloadTime: 10
  },
  {
    name: 'Breadbear',
    image: require('./images/breadbear.png').default,
    imageScale: 0.12,
    imageOffsetY: 22,
    speed: 500,
    jumpForce: 1000,
    maxJumps: 2,
    bulletType: BasicHitBullet,
    width: 80,
    height: 73,
    reloadTime: 10
  }
]
