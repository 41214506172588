import Gamepads from 'gamepads'
import characterInfo from '../character-info'

// const willeFull = 'Xbox 360 Controller (STANDARD GAMEPAD Vendor: 045e Product: 028e)'
// const julleFull = 'usb gamepad            (Vendor: 0810 Product: e501)'

const willePart = 'Xbox 360 Controller'
const jullePart = 'usb gamepad'

const setupMapping = (gamepad) => {
  const isWille = gamepad?.gamepad?.id?.includes(willePart)
  const isJulle = gamepad?.gamepad?.id?.includes(jullePart)

  if (isJulle) {
    return {
      name: 'Julle',
      up: [Gamepads.StandardMapping.Button.D_PAD_UP],
      down: [Gamepads.StandardMapping.Button.D_PAD_BOTTOM],
      left: [Gamepads.StandardMapping.Button.D_PAD_LEFT],
      right: [Gamepads.StandardMapping.Button.D_PAD_RIGHT],
      jump: [2, 1],
      shoot: [0, 3]
    }
  }

  return {
    name: isWille ? 'Wille' : null,
    up: [Gamepads.StandardMapping.Button.D_PAD_UP],
    down: [Gamepads.StandardMapping.Button.D_PAD_BOTTOM],
    left: [Gamepads.StandardMapping.Button.D_PAD_LEFT],
    right: [Gamepads.StandardMapping.Button.D_PAD_RIGHT],
    jump: [Gamepads.StandardMapping.Button.BUTTON_BOTTOM, Gamepads.StandardMapping.Button.BUTTON_RIGHT],
    shoot: [Gamepads.StandardMapping.Button.BUTTON_TOP, Gamepads.StandardMapping.Button.BUTTON_LEFT]
  }
}

export default function GamepadController (index) {
  let gamepad
  let mapping = setupMapping()

  return {
    reset: () => {
    },
    setupForCharacterSelect: ([_, setCharIndex], startGame, onName) => {
      const onPress = (ev) => {
        if (mapping.up.includes(ev.index)) {
          setCharIndex(i => i <= 0 ? characterInfo.length - 1 : i - 1)
        }
        if (mapping.down.includes(ev.index)) {
          setCharIndex(i => (i + 1) % characterInfo.length)
        }

        if (mapping.shoot.includes(ev.index)) {
          startGame()
        }
      }
      const onAxis = (ev) => {
        const up = ev.index === 1 && ev.value <= -0.9
        const down = ev.index === 1 && ev.value >= 0.9

        if (up) {
          setCharIndex(i => i <= 0 ? characterInfo.length - 1 : i - 1)
        }
        if (down) {
          setCharIndex(i => (i + 1) % characterInfo.length)
        }
      }

      const onDisconnect = () => {
        if (gamepad) {
          gamepad.removeEventListener('buttonpress', onPress)
          gamepad.removeEventListener('axischange', onAxis)
        }
      }
      const onConnect = () => {
        onDisconnect()
        gamepad = Gamepads.gamepads[index]
        mapping = setupMapping(gamepad)

        if (mapping.name && onName) onName(mapping.name)

        if (gamepad) {
          gamepad.addEventListener('buttonpress', onPress)
          gamepad.addEventListener('axischange', onAxis)
        }
      }

      Gamepads.addEventListener('connect', onConnect)
      Gamepads.addEventListener('disconnect', onDisconnect)

      onConnect()

      return () => {
        onDisconnect()
        Gamepads.removeEventListener('connect', onConnect)
        Gamepads.removeEventListener('disconnect', onDisconnect)
      }
    },
    setupForRestart: (onRestart) => {
      let gamepad

      const onPress = (ev) => {
        if (mapping.shoot.includes(ev.index)) {
          onRestart()
        }
      }

      const onDisconnect = () => {
        if (gamepad) {
          gamepad.removeEventListener('buttonpress', onPress)
        }
      }
      const onConnect = () => {
        onDisconnect()
        gamepad = Gamepads.gamepads[index]
        mapping = setupMapping(gamepad)

        if (gamepad) {
          gamepad.addEventListener('buttonpress', onPress)
        }
      }

      Gamepads.addEventListener('connect', onConnect)
      Gamepads.addEventListener('disconnect', onDisconnect)

      onConnect()

      return () => {
        console.log('remove')
        onDisconnect()
        Gamepads.removeEventListener('connect', onConnect)
        Gamepads.removeEventListener('disconnect', onDisconnect)
      }
    },
    setup: (state) => {},
    update: (state, delta) => {
      state.isMoving = false

      if (!gamepad) {
        gamepad = Gamepads.gamepads[index]
        mapping = setupMapping(gamepad)
      }

      if (gamepad) {
        const isJumping = gamepad.getButton(mapping.jump[0]).value || gamepad.getButton(mapping.jump[1]).value
        const isShooting = gamepad.getButton(mapping.shoot[0]).value || gamepad.getButton(mapping.shoot[1]).value

        if (isShooting) {
          if (!state.wasShooting) {
            state.shoot = true
          }
          state.wasShooting = true
        } else {
          state.wasShooting = false
        }

        if (isJumping) {
          if (!state.wasJumping) {
            state.jump = true
          }
          state.wasJumping = true
        } else {
          state.wasJumping = false
        }

        const xAxis = Math.round(gamepad.getAxis(0))

        const leftButton = gamepad.getButton(mapping.left[0]) || xAxis <= -0.9
        const rightButton = gamepad.getButton(mapping.right[0]) || xAxis >= 0.9

        if (leftButton === true || (leftButton && leftButton.value)) {
          state.isMoving = true
          state.xSpeed = Math.max(-1, state.xSpeed - 0.1 * delta)
          state.xDir = -1

          const targetVX = -1 * state.speedMod * state.info.speed
          if (state.entity.vx > targetVX) {
            const vxDiff = Math.min(state.speedMod * -state.info.speed, targetVX - state.entity.vx)
            const vxAdd = vxDiff * 0.05 * delta
            state.entity.vx += vxAdd
          }
        }
        if (rightButton === true || (rightButton && rightButton.value)) {
          state.isMoving = true
          state.xDir = 1

          const targetVX = 1 * state.speedMod * state.info.speed
          if (state.entity.vx < targetVX) {
            const vxDiff = Math.min(state.speedMod * state.info.speed, targetVX - state.entity.vx)
            const vxAdd = vxDiff * 0.05 * delta
            state.entity.vx += vxAdd
          }
        }
      }
    }
  }
}

GamepadController.setup = () => {
  Gamepads.start()
}
