import './style'
import { useState } from 'preact/hooks'
import kd from 'keydrown'

import CharacterSelectRoute from './routes/CharacterSelect'
import GameRoute from './routes/Game'
import IntroRoute from './routes/Intro'

import KeyboardController from './controllers/KeyboardController'
import GamepadController from './controllers/GamepadController'

GamepadController.setup()

const controllers = [
  // KeyboardController([kd.A, kd.D, kd.W, kd.G]),
  // KeyboardController([kd.LEFT, kd.RIGHT, kd.UP, kd.DOWN])
  GamepadController(0),
  GamepadController(1)
]

function Router ({ route, setRoute, characterIndices, setCharacterIndices }) {
  switch (route) {
    case 'intro': {
      return (
        <IntroRoute
          setRoute={setRoute}
        />
      )
    }
    case 'character-select': {
      return (
        <CharacterSelectRoute
          controllers={controllers}
          setRoute={setRoute}
          setCharacterIndices={setCharacterIndices}
        />
      )
    }
    case 'game': {
      return (
        <GameRoute
          setRoute={setRoute}
          controllers={controllers}
          characterIndices={characterIndices}
        />
      )
    }
  }
}

export default function App () {
  const [route, setRoute] = useState('intro')
  // const [route, setRoute] = useState('game')
  const [characterIndices, setCharacterIndices] = useState([2, 3])

  return (
    <div>
      <Router
        route={route}
        setRoute={setRoute}
        characterIndices={characterIndices}
        setCharacterIndices={setCharacterIndices}
      />
    </div>
  )
}
