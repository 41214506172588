import * as PIXI from 'pixi.js'
// import Bump from 'pixi-plugin-bump/src/Bump'
import circleBox from 'intersects/circle-box'

let bulletIdCounter = 0

export default function FireballBullet (x, y, xDir, characterId) {
  const radius = 10

  const node = new PIXI.Container()
  node.x = x
  node.y = y
  node.zIndex = 10

  // const circle = new PIXI.Graphics()
  // circle.beginFill(0x00ff00)
  // circle.drawCircle(0, 0, radius)
  // circle.circular = true
  // node.addChild(circle)

  const graphic = PIXI.Sprite.from(require('../images/kaoserfire.png').default)
  const graphicScale = 0.3
  graphic.scale.x = graphicScale
  graphic.scale.y = graphicScale
  graphic.anchor.x = 0.5
  graphic.anchor.y = 0.5
  node.addChild(graphic)

  const id = ++bulletIdCounter
  const speed = 8
  let jiggleCount = 0
  let duration = 300

  return {
    id,
    node,
    setup: function (parentNode, world) {
      if (!node.parent && !this.isRemoved) {
        parentNode.addChild(node)
      }
    },
    update: function (delta, gameState) {
      if (!node.parent || this.isRemoved) return

      node.x += xDir * delta * speed

      jiggleCount += delta
      node.rotation = Math.sin(jiggleCount) * 0.2

      // const b = new Bump()

      for (const character of gameState.characters) {
        if (character.id === characterId) continue

        const intersected = circleBox(
          node.x,
          node.y,
          radius,
          character.node.x - character.referenceRectangle.width / 2,
          character.node.y - character.referenceRectangle.height / 2,
          character.referenceRectangle.width,
          character.referenceRectangle.height
        )

        // if (b.hitTestCircleRectangle(circle, character.referenceRectangle, true)) {
        if (intersected) {
          const power = 20

          gameState.addToCameraShake(power / 2)
          character.hit(xDir, power)

          // Remove on hit
          node.parent.removeChild(node)
          this.isRemoved = true
          break
        }
      }

      duration -= delta
      if (node.parent && duration <= 0) {
        node.parent.removeChild(node)
        this.isRemoved = true
      }
    },
    render: () => {}
  }
}
